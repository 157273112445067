import React, { useContext } from 'react';
import { Empty, Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { JobsResponseItem } from 'types/types';
import JobsDiff from './JobsDiff/JobsDiff';
import InformationTab from './InformationTab/informationTab';
import { AppContext } from 'contexts/AppContext';
import { useGetJobCode, useGetJobProperties } from 'hooks/jobs';
import SpinnerChidori from 'components/SpinnerChidori/SpinnerChidori';
import './JobsCompare.scss';

interface JobsCompareProps {
  jobs: JobsResponseItem[];
}

const JobsCompare: React.FC<JobsCompareProps> = ({ jobs }) => {
  const { clusterName } = useContext(AppContext);

  const {
    data: job1,
    isLoading: isLoadingJob1,
    isError: errProperties1,
  } = useGetJobProperties({
    clusterName,
    jobId: jobs[0].SparkJobID,
  });

  const {
    data: job2,
    isLoading: isLoadingJob2,
    isError: errProperties2,
  } = useGetJobProperties({
    clusterName,
    jobId: jobs[1].SparkJobID,
  });

  const {
    data: jobCode1,
    isLoading: isLoadingJobCode1,
    isError: errCode1,
  } = useGetJobCode({
    clusterName,
    jobId: jobs[0].SparkJobID,
    mvLanguage: jobs[0].MVLanguage,
  });

  const {
    data: jobCode2,
    isLoading: isLoadingJobCode2,
    isError: errCode2,
  } = useGetJobCode({
    clusterName,
    jobId: jobs[1].SparkJobID,
    mvLanguage: jobs[1].MVLanguage,
  });

  const onChange = (key: string) => {
    console.log(`Active tab: ${key}`);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Jobs Information',
      children: <InformationTab jobs={jobs} />,
    },
    {
      key: '2',
      label: 'Jobs Properties',
      children: (
        <div className="jobs-properties">
          {errProperties1 || errProperties2 ? (
            <div className="jobs-error">
              <Empty description="No Data Available" />
            </div>
          ) : isLoadingJob1 || isLoadingJob2 ? (
            <div className="spinner-chidori">
              <SpinnerChidori />
            </div>
          ) : job1 && job2 ? (
            <JobsDiff jobs={jobs} oldCode={job1} newCode={job2} />
          ) : (
            <div className="spinner-chidori">
              <SpinnerChidori />
            </div>
          )}
        </div>
      ),
    },
    {
      key: '3',
      label: 'Jobs Code',
      children: (
        <div className="jobs-code">
          {errCode1 || errCode2 ? (
            <div className="jobs-error">
              <Empty description="No Data Available" />
            </div>
          ) : isLoadingJobCode1 || isLoadingJobCode2 ? (
            <div className="spinner-chidori">
              <SpinnerChidori />
            </div>
          ) : jobCode1 && jobCode2 ? (
            <JobsDiff jobs={jobs} oldCode={jobCode1} newCode={jobCode2} />
          ) : (
            <div className="spinner-chidori">
              <SpinnerChidori />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="jobs-compare">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default JobsCompare;
