import './JobRowInfoCard.scss';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { copyJobID } from 'helpers/clipboard';
import { FormattedMessage } from 'react-intl';

function JobRowInfoCard({
  sparkJobID,
  vendor,
  mVLanguage,
  totalExecutorCount = -1,
  peakMemoryInMB = 0,
  sparkDriverMemoryInMB = 0,
}: {
  sparkJobID: string;
  vendor: string;
  mVLanguage: string;
  totalExecutorCount?: number;
  peakMemoryInMB?: number;
  sparkDriverMemoryInMB?: number;
}) {
  return (
    <section className="job-row-info-card__icon-wrapper">
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title job-id">
          <span>
            <FormattedMessage id="actions.info.column.jobID" />
          </span>
          <Tooltip
            title={<FormattedMessage id="actions.info.column.jobID.title" />}>
            <CopyOutlined
              className="copy-icon"
              onClick={() => copyJobID(sparkJobID)}
            />
          </Tooltip>
        </span>
        <span>{sparkJobID}</span>
      </article>

      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.service" />
        </span>
        <span>{vendor}</span>
      </article>

      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.language" />
        </span>
        <span>{mVLanguage}</span>
      </article>
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.totalExecutorCount" />
        </span>
        <span>
          {totalExecutorCount === -1
            ? 'Executors not found'
            : totalExecutorCount.toString()}
        </span>
      </article>
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.peakMemory" />
        </span>
        <span>
          {peakMemoryInMB === 0
            ? 'Unable to capture peak memory usage'
            : `${(peakMemoryInMB / 1024 ** 2).toFixed(2)} GB`}
        </span>
      </article>
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.sparkDriverMemory" />
        </span>
        <span>
          {sparkDriverMemoryInMB === 0
            ? 'Unable to capture "spark.driver.memory" property'
            : `${sparkDriverMemoryInMB} GB`}
        </span>
      </article>
    </section>
  );
}

export default JobRowInfoCard;
